import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText
} from 'reactstrap';

import { Utils } from "custom";
import { ClassRoomInsideAPI } from "api";

class EditStudent extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            form: {
                id: (preset) ? preset.id : null,
                firstname: (preset) ? preset.firstname : "",
                lastname: (preset) ? preset.lastname : "",
                email: (preset) ? preset.email : "",
                cell: (preset) ? preset.cell : ""
            },
            loading: false,
            errors: false
        };
    }

    changeForm(key, text) {
        const form = this.state.form;

        if (key === 'cell') {
            if ((isNaN(text) && text !== "+") || text.indexOf(".") > -1)
                return;

            if (text.length >= 3 && text.indexOf("+") === -1)
                text = "+39" + text;

            text = text.replace(/ /g, "");
        }

        form[key] = text;

        this.setState({form: form}, () => this.showModal());
    }

    showModal() {
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: "MODIFICA CORSISTA",
            content: <div>
                <Row>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome
                            </Label>
                            <Input type="text" value={form.firstname}
                                   invalid={Boolean(errors.firstname)}
                                   onChange={(e) => this.changeForm('firstname', e.target.value)}/>
                            <FormFeedback>{errors.firstname}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Cognome
                            </Label>
                            <Input type="text" value={form.lastname}
                                   invalid={Boolean(errors.lastname)}
                                   onChange={(e) => this.changeForm('lastname', e.target.value)}/>
                            <FormFeedback>{errors.lastname}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Email
                            </Label>
                            <Input type="email" value={form.email}
                                   invalid={Boolean(errors.email)}
                                   onChange={(e) => this.changeForm('email', e.target.value)}/>
                            <FormFeedback>{errors.email}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Cellulare
                            </Label>
                            <Input type="tel" value={form.cell}
                                   invalid={Boolean(errors.cell)}
                                   onChange={(e) => this.changeForm('cell', e.target.value)}/>
                            <FormFeedback>{errors.cell}</FormFeedback>
                            <FormText>
                                Assicurati di digitare correttamente il <u>numero di cellulare</u>, è necessario inserire anche il prefisso internazionale (+39 per Italia)
                            </FormText>
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.saveClient()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                }
            ]
        })
    }

    resetForm() {
        let preset = this.props.preset;
        this.setState({
            form: {
                id: (preset) ? preset.id : null,
                firstname: (preset) ? preset.firstname : "",
                lastname: (preset) ? preset.lastname : "",
                email: (preset) ? preset.email : "",
                cell: (preset) ? preset.cell : ""
            },
            errors: false
        });
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    saveClient() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.firstname === '')
            error.firstname = "Il campo nome è obbligatorio";

        if (form.lastname === '')
            error.lastname = "Il campo cognome è obbligatorio";

        if (form.cell !== '' && !Utils.isValidCell(form.cell))
            error.cell = "Il cellulare inserito non è corretto";

        if (form.cell === '')
            error.cell = "Il campo cellulare è obbligatorio";

        if (!Utils.isValidEmail(form.email))
            error.email = "La mail inserita non è corretta";

        if (form.email === '')
            error.email = "Il campo email è obbligatorio";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});


            form.id_classroom = this.props.classroomId;

            ClassRoomInsideAPI.clientEdit(form).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Corsista modificato',
                    text: 'Il corsista è stato creato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch((err) => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la modifica del cliente',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li>
            </React.Fragment>
        )
    }
}

export default EditStudent;
