import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText,
    Badge
} from 'reactstrap';

import { Utils } from "custom";
import { ClassRoomInsideAPI, ClassRoomTrialAPI, TeacherAPI } from "api";
import Select from "react-select";
import DatePicker, {DateObject} from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import InputIcon from "react-multi-date-picker/components/input_icon";


const moment = require("moment")

const GestClassroomMultipleLesson = ({ classroom, id_classroom, onResolve }) => {
    const [form, setForm] = useState({
        dates: [],
        url_lesson: "",
    });
    const [modalVisible, setModalVisible] = useState(false);

    const [errors, setErrors] = useState(false);

    useEffect(() => {
        if (modalVisible)
            showModal();
    }, [form, errors]);


    const changeForm = (key, value) => {
        setForm(prevForm => ({ ...prevForm, [key]: value }));
    };


    const handleDateChange = (selectedDates) => {
        const formattedDates = selectedDates.map(date => {
            return moment(new Date(date)).format("YYYY-MM-DD");
        });

        console.log("Date formattate:", formattedDates);
        setForm(prevForm => ({ ...prevForm, dates: formattedDates }));
    };



    const showModal = () => {
        setModalVisible(true);

        const gregorian_it = {
            name: "gregorian_it",
            months: [
                ["gennaio", "gen"],
                ["febbraio", "feb"],
                ["marzo", "mar"],
                ["aprile", "apr"],
                ["maggio", "mag"],
                ["giugno", "giu"],
                ["luglio", "lug"],
                ["agosto", "ago"],
                ["settembre", "set"],
                ["ottobre", "ott"],
                ["novembre", "nov"],
                ["dicembre", "dic"],
            ],
            weekDays: [
                ["sabato", "sab"],
                ["domenica", "dom"],
                ["lunedì", "lun"],
                ["martedì", "mar"],
                ["mercoledì", "mer"],
                ["giovedì", "gio"],
                ["venerdì", "ven"]
            ],
            digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
            weekStartDayIndex: 1,
            meridiems: [
                ["AM", "am"],
                ["PM", "pm"],
            ],
        };

        global.onSetModal({
            title: "CREA LEZIONI",
            content: (
                <div>
                    <Row>
                        <Col>
                            <div className='mb-2 text-center'>
                                <small>Seleziona i giorni</small><br />
                                <DatePicker
                                    multiple
                                    format="DD MMM YYYY"
                                    sort
                                    weekStartDayIndex={1}
                                    locale={gregorian_it}
                                    minDate={moment(classroom.date_start, "YYYY-MM-DD").toDate()}
                                    maxDate={moment(classroom.date_end, "YYYY-MM-DD").toDate()}
                                    plugins={[<DatePanel key="date-panel" />]}
                                    className="rmdp-mobile"
                                    mobileLabels={{
                                        OK: "Conferma",
                                        CANCEL: "Chiudi",
                                    }}
                                    onChange={handleDateChange}
                                />
                                <br />
                                {(errors.date) ? <small className="text-danger">{errors.date}</small> : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='mb-2'>
                                <Label className='form-label'>Link Accesso Lezione</Label><br />
                                <Input type="text" placeholder="Esempio: https://us02web.zoom.us/j/123456789" value={form.url_lesson} invalid={Boolean(errors?.url_lesson)} onChange={(e) => changeForm("url_lesson", e.target.value)} />
                                <FormFeedback>{errors?.url_lesson}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    {(errors.general) ? <p className="text-danger">{errors.general}</p> : null}
                </div>
            ),
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [
                { label: 'SALVA', color: 'success', onClick: () => saveLesson() },
                { label: 'CHIUDI', color: 'danger', onClick: () => resetForm() }
            ]
        });
    };

    const resetForm = () => {
        setModalVisible(false);
        setForm({
            dates: [],
            url_lesson: "",
        });
        setErrors(false)
        global.onSetModal({ isOpen: false, loading: false, content: "" });
    };

    const saveLesson = async () => {

        console.log(form)

        let error = {};
        if (form.dates.length === 0) error.date = "È necessario selezionare almeno una data";
        if (!Utils.isValidUrl(form.url_lesson)) error.url_lesson = "Il link non è corretto";

        setErrors(error);
        if (Object.keys(error).length === 0) {
            global.onSetModal({ loading: true });

            try {
                for (let i = 0; i < form.dates.length; i++) {
                    for (let d = 0; d < classroom.groups.length; d++) {
                        let hour = classroom.groups[d].slot.split(" - ")[0]
                        await ClassRoomInsideAPI.lessonAdd({
                            id_classroom,
                            date_hour: `${form.dates[i]} ${hour}:00`,
                            url_lesson: form.url_lesson,
                        })
                    }
                }
                resetForm();
                onResolve();
            } catch (e) {
                error.general = "Si è verificato un errore durante il caricamento delle lezioni"
                global.onSetModal({ loading: false });
                setErrors(error);
                onResolve();
            }
        }
    };

    require("react-multi-date-picker/styles/layouts/mobile.css")

    return (
        <Button color="primary" size="sm" className={"m-1"} onClick={showModal}>
            <i className="ri-calendar-todo-fill align-bottom me-1"></i> CREA LEZIONE MULTIPLA
        </Button>
    );
};

export default GestClassroomMultipleLesson;
