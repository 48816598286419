import React, { useEffect, useState } from 'react';
import {
    Alert,
    Badge, Button,
    Card,
    CardBody,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback, FormText, Input, Label, Nav, NavItem, NavLink,
    Row, Spinner, TabContent, TabPane, UncontrolledDropdown
} from 'reactstrap';

import { ClassRoomAPI, ClassRoomInsideAPI, LeadAPI, PaymentAPI } from "api";
import { Link, useParams, useHistory } from "react-router-dom";
import {
    DeleteClassroomLesson, EditStudent,
    GestClassroomLesson,
    Loading,
    RevertClassroom,
    Utils
} from "custom";

import { _, Grid } from "gridjs-react";

const moment = require("moment");

const StudentInside = ({ groups, onResolve, classroomId }) => {
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [classroom, setClassroom] = useState(null);
    const [error, setError] = useState(false);
    const [groupsClassroom, setGroupsClassroom] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const isAdmin = (Utils.getUserData().role === "ADMIN");
    const isTeacher = (Utils.getUserData().role === "TEACHER");
    const isConsulent = (Utils.getUserData().role === "CONSULENT");

    const { id } = useParams();

    useEffect(() => {
        setGroupsClassroom(() => generateGroups(groups));
    }, [groups]);

    const generateGroups = (data) => {
        if (data.length === 1) {
            return data;
        }

        const [group1, group2] = data;

        const turnover = group1.partecipants.filter(p1 =>
            group2.partecipants.some(p2 => p1.id === p2.id)
        );

        const filteredGroup1 = {
            ...group1,
            name: `Fisso ore ${group1.slot.split(" - ")[0]}`,
            partecipants: group1.partecipants.filter(p => !turnover.some(t => t.id === p.id))
        };

        const filteredGroup2 = {
            ...group2,
            name: `Fisso ore ${group2.slot.split(" - ")[0]}`,
            partecipants: group2.partecipants.filter(p => !turnover.some(t => t.id === p.id))
        };

        const newGroups = [
            filteredGroup1,
            filteredGroup2,
            {
                id: null,
                name: "Gruppo Turnisti",
                slot: "Turnisti",
                limit: turnover.length,
                available_id: null,
                partecipants: turnover
            }
        ];

        return newGroups;
    };

    const filteredGroups = groupsClassroom.map(group => ({
        ...group,
        partecipants: group.partecipants.filter(p =>
            searchTerm === "" ||
            `${p.firstname} ${p.lastname}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
            p.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            p.cell.includes(searchTerm)
        )
    }));

    const showDetailAttendances = async (student) => {
        global.onSetModal({ isOpen: false, content: null, loading: true });

        try {
            let attendances = await ClassRoomInsideAPI.detailAttendances(classroomId, student.id);

            global.onSetModal({
                title: "Dettaglio presenze",
                content: <div>
                    <h5 className="card-title text-center"><strong>{student.firstname + " " + student.lastname}</strong></h5>
                    <div className="acitivity-timeline py-3">
                        {attendances.map((a, i) => (
                            <div className="acitivity-item py-3 d-flex" key={i}>
                                <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                                    <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                                        <i className="mdi mdi-calendar-clock" />
                                    </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <h6 className="mb-1"><b>{moment(a.date_hour).format("ddd DD MMM YYYY")}</b></h6>
                                    <p className="mb-1"><b>ore {moment(a.date_hour).format("HH:mm")}</b></p>
                                    {(a.check) ? <Badge color={"success"}>Presente</Badge> : <Badge color={"danger"}>Assente</Badge>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>,
                isOpen: true,
                toggle: false,
                loading: false,
                size: 'md',
                type: 'info',
                buttons: [
                    {
                        label: 'CHIUDI',
                        color: 'primary',
                        onClick: () => global.onSetModal({ isOpen: false, content: null, loading: false })
                    }
                ]
            });
        } catch (e) {
            global.SweetAlert.fire({
                title: 'Errore',
                text: 'Si è verificato un errore durante il recupero delle presenze',
                icon: 'error',
                customClass: { confirmButton: 'btn btn-primary' }
            });
        }
    };

    return (
        <React.Fragment>
            <Row className="g-4 mb-3">
                <div className="col-sm">
                    <div className="d-flex">
                        <div className="search-box me-2">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Cerca corsista..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <i className="ri-search-line search-icon"></i>
                        </div>
                    </div>
                </div>
            </Row>

            {filteredGroups.map((g, index) => {
                return <div className="team-list list-view-filter pt-2 pb-2" key={index}>
                    <h5><b>{g.name}</b></h5>
                    {g.partecipants.map((p, index2) => {
                        return <Card className="team-box" key={index2}>
                            <CardBody className="px-4">
                                <Row className="align-items-center team-row">
                                    <Col lg={4}>
                                        <div className="team-profile-img">
                                            <div className="avatar-lg img-thumbnail rounded-circle">
                                                <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                                                    {p.firstname[0]}{p.lastname[0]}
                                                </div>
                                            </div>
                                            <div className="team-content">
                                                <h5 className="fs-16 mb-1">{(isAdmin || isConsulent) ? <span className="text-primary cursor-pointer" onClick={() => history.push("/client/" + p.id)}>{p.firstname} {p.lastname}</span> : <span>{p.firstname} {p.lastname} <EditStudent classroomId={classroomId} preset={p} /></span>}</h5>
                                                {(!p.active) ? <p className="text-danger mb-0"><b>Utente non attivo</b></p> : null}
                                                {(isAdmin || isTeacher) ? <span>
                                                    {(p.email) ? <p className="text-muted mb-0">{p.email}</p> : <p className="text-danger mb-0"><b>Email mancante</b></p>}
                                                    {(p.cell) ? <span>
                                                        <p className="text-muted mb-0">{p.cell}</p>
                                                        <a href={"https://api.whatsapp.com/send?phone=" + p.cell.slice(1)}><Badge
                                                            style={{ fontSize: "12px" }} color="success"><i
                                                            className="mdi mdi-whatsapp"></i> Whatsapp</Badge>
                                                        </a>&nbsp;&nbsp;
                                                        <a href={"tel:" + p.cell}><Badge
                                                            style={{ fontSize: "12px" }} color="primary"><i
                                                            className="mdi mdi-phone"></i> Chiama</Badge></a>
                                                    </span> : <p className="text-danger mb-0">Cellulare mancante</p>}
                                                </span> : null}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <h5 className="mb-1">{p.consulent}</h5>
                                        <p className="text-muted mb-0">Consulente di riferimento</p>
                                    </Col>
                                    <Col lg={3}>
                                        <Row className="text-muted text-center">
                                            <Col xs={12} className="border-end border-end-dashed">
                                                <h5 className="mb-1">{p.attendances}</h5>
                                                <p className="text-muted mb-0">Presenze</p>
                                                <span className="btn btn-sm btn-light view-btn" onClick={() => showDetailAttendances(p)}>Dettagli presenze</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    })}
                    {(g.partecipants.length === 0) ? <Alert color="dark">Nessun corsista trovato</Alert> : null}
                </div>
            })}
        </React.Fragment>
    );
};

export default StudentInside;
