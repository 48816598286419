import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Utils } from "custom";

const Navdata = () => {
    let permissionsAdmin = Utils.getUserData().permissions;

    let role = Utils.getUserData().role;

    let is_networker = Utils.getUserData().is_networker;

    let menuItems = [];

    switch (role) {
        case "ADMIN":
            menuItems = [
                {
                    id: "dashboard",
                    label: "Dashboard",
                    icon: "mdi mdi-view-dashboard",
                    link: "/dashboard",
                    disabled: !permissionsAdmin.includes("DASHBOARD")
                },
                {
                    label: "Anagrafica",
                    isHeader: true,
                },
                {
                    id: "administrators",
                    label: "Amministratori",
                    icon: "mdi mdi-key",
                    link: "/admin",
                    disabled: !permissionsAdmin.includes("ADMIN")
                },
                {
                    id: "consulents",
                    label: "Consulenti",
                    icon: "mdi mdi-briefcase",
                    link: "/consulent",
                    disabled: !permissionsAdmin.includes("CONSULENT")
                },
                {
                    id: "teachers",
                    label: "Docenti",
                    icon: "mdi mdi-account",
                    link: "/teachers",
                    disabled: !permissionsAdmin.includes("TEACHER")
                },
                {
                    id: "clients",
                    label: "Clienti",
                    icon: "mdi mdi-account-group",
                    link: "/client",
                    disabled: !permissionsAdmin.includes("CLIENT")
                },
                {
                    id: "network",
                    label: "Network",
                    icon: "mdi mdi-sitemap",
                    link: "/network",
                    disabled: !permissionsAdmin.includes("REPORT")
                },
                {
                    label: "Risultati",
                    isHeader: true,
                },
                {
                    id: "leads",
                    label: "Lead",
                    icon: "mdi mdi-phone",
                    link: "/lead",
                    disabled: !permissionsAdmin.includes("LEAD")
                },
                {
                    id: "sells",
                    label: "Vendite",
                    icon: "mdi mdi-file-document-edit",
                    link: "/sell",
                    disabled: !permissionsAdmin.includes("SELL")
                },
                {
                    id: "payments",
                    label: "Pagamenti",
                    icon: "mdi mdi-currency-eur",
                    link: "/payment",
                    disabled: !permissionsAdmin.includes("PAYMENT")
                },
                {
                    label: "Corsi",
                    isHeader: true,
                },
                {
                    id: "classrooms",
                    label: "Classi",
                    icon: "mdi mdi-school",
                    link: "/classroom",
                    disabled: !permissionsAdmin.includes("CLASSROOM")
                },
                {
                    id: "classrooms-individual",
                    label: "Classi Individuali",
                    icon: "mdi mdi-cast-education",
                    link: "/classroom/list",
                    disabled: true
                },
                {
                    id: "classroomsTrial",
                    label: "Lezioni Gratuite",
                    icon: "mdi mdi-calendar-clock",
                    link: "/classroom/trial",
                    disabled: !permissionsAdmin.includes("CLASSROOM")
                },
                {
                    id: "course",
                    label: "Video Corsi",
                    icon: "mdi mdi-video",
                    link: "/course",
                    disabled: !permissionsAdmin.includes("COURSE")
                },
                {
                    id: "assistenza",
                    label: "Assistenza",
                    icon: "mdi mdi-phone-message",
                    link: "/assistance",
                    disabled: !permissionsAdmin.includes("TICKET")
                },
                {
                    label: "Marketing",
                    isHeader: true,
                },
                {
                    id: "modules",
                    label: "Moduli",
                    icon: "mdi mdi-cog-sync",
                    link: "/module",
                    disabled: !permissionsAdmin.includes("LEAD_MODULE")
                },
                {
                    id: "stats",
                    label: "Statistiche",
                    icon: "mdi mdi-finance",
                    link: "/stats",
                    disabled: true
                },
                {
                    id: "stats",
                    label: "Email",
                    icon: "mdi mdi-email-fast",
                    link: "/email",
                    disabled: true
                },
                {
                    id: "stats",
                    label: "Campagne",
                    icon: "mdi mdi-bullhorn",
                    link: "/email",
                    disabled: true
                },
            ];
            break;
        case "CONSULENT":
            menuItems = [
                {
                    label: "Menu",
                    isHeader: true,
                },
                {
                    id: "dashboard",
                    label: "Dashboard",
                    icon: "mdi mdi-view-dashboard",
                    link: "/dashboard",
                    disabled: false
                },
                {
                    id: "leads",
                    label: "Lead",
                    icon: "mdi mdi-phone",
                    link: "/lead",
                    disabled: false
                },
                {
                    id: "classrooms",
                    label: "Classi",
                    icon: "mdi mdi-school",
                    link: "/classroom",
                    disabled: false
                },
                {
                    id: "clients",
                    label: "Clienti",
                    icon: "mdi mdi-account-group",
                    link: "/client",
                    disabled: false
                },
                {
                    id: "network",
                    label: "Network",
                    icon: "mdi mdi-sitemap",
                    link: "/network",
                    disabled: false
                },
                {
                    id: "sells",
                    label: "Vendite",
                    icon: "mdi mdi-file-document-edit",
                    link: "/sell",
                    disabled: false
                },
                {
                    id: "payments",
                    label: "Pagamenti",
                    icon: "mdi mdi-currency-eur",
                    link: "/payment",
                    disabled: false
                },
                {
                    label: "Corsi",
                    isHeader: true,
                },
                {
                    id: "course",
                    label: "Video Corsi",
                    icon: "mdi mdi-video",
                    link: "/course",
                    disabled: false
                },
                {
                    id: "classroomsTrial",
                    label: "Lezioni di prova",
                    icon: "mdi mdi-calendar-clock",
                    link: "/classroom/trial",
                    disabled: false
                },
            ];
            break;
        case "CLIENT":
            menuItems = [
                {
                    label: "Menu",
                    isHeader: true,
                },
                {
                    id: "network",
                    label: "Network",
                    icon: "mdi mdi-sitemap",
                    link: "/network",
                    disabled: !(is_networker)
                },
                {
                    id: "classrooms",
                    label: "Classi",
                    icon: "mdi mdi-school",
                    link: "/classroom/list",
                    disabled: false
                },
                {
                    id: "course",
                    label: "Corsi",
                    icon: "mdi mdi-school",
                    link: "/course",
                    disabled: false
                },
                {
                    id: "assistenza",
                    label: "Assistenza",
                    icon: "mdi mdi-phone-message",
                    link: "/assistance",
                    disabled: true
                }
            ];
            break;
        case "TEACHER":
            menuItems = [
                {
                    label: "Menù",
                    isHeader: true,
                },
                {
                    id: "network",
                    label: "Network",
                    icon: "mdi mdi-sitemap",
                    link: "/network",
                    disabled: !(is_networker)
                },
                {
                    id: "available",
                    label: "Disponibilità",
                    icon: "mdi mdi-calendar",
                    link: "/available",
                    disabled: false
                },
                {
                    id: "classrooms",
                    label: "Classi",
                    icon: "mdi mdi-school",
                    link: "/classroom/list",
                    disabled: false
                },
                {
                    id: "classroomsTrial",
                    label: "Lezioni di prova",
                    icon: "mdi mdi-calendar-clock",
                    link: "/classroom/trial",
                    disabled: false
                },
                {
                    id: "course",
                    label: "Video Corsi",
                    icon: "mdi mdi-video",
                    link: "/course",
                    disabled: false
                }
            ];
            break;
        default:
            menuItems = [];
            break;
    }

    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
