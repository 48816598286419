
let detailInside = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classRoomInside + '/' + id, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'GET',
            // body: JSON.stringify({
            //     id: id
            // })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('classroom inside', responseJson);
                if (responseJson.status === "DETAIL_CLASSROOM_INSIDE")
                    resolve(responseJson.classroom);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};
let postAdd = function(payload) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classRoomPost, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(payload)
        })
        .then((response) => (response.status !== 500) ? response.json() : reject())
        .then((responseJson) => {
            global.checkAuth(responseJson.status);
            console.log('CLASSROOM_POST_CREATED', responseJson);
            if (responseJson.status === "CLASSROOM_POST_CREATED")
                resolve(responseJson);
            else
                reject(responseJson);
        })
        .catch((e) => {
            console.error(e.message);
            reject('APP_ERROR');
        });
    });
};

let postEdit = function(payload) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classRoomPost, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(payload)
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('CLASSROOM_POST_EDITED', responseJson);
                if (responseJson.status === "CLASSROOM_POST_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let postRemove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classRoomPost, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('classroom post delete', responseJson);
                if (responseJson.status === "CLASSROOM_POST_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let lessonAdd = function(payload) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classRoomLesson, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(payload)
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('CLASSROOM_LESSON_CREATED', responseJson);
                if (responseJson.status === "CLASSROOM_LESSON_CREATED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let lessonEdit = function(payload) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classRoomLesson, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(payload)
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('CLASSROOM_LESSON_EDITED', responseJson);
                if (responseJson.status === "CLASSROOM_LESSON_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let lessonRemove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classRoomLesson, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('classroom lesson delete', responseJson);
                if (responseJson.status === "CLASSROOM_LESSON_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};


let lessonCheckIn = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classRoomLesson + '/' + id, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'GET',
            // body: JSON.stringify({
            //     id: id
            // })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('classroom lesson check in', responseJson);
                if (responseJson.status === "LESSON_CHECKIN_OK")
                    resolve(responseJson.url_lesson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let detailAttendances = function(id_classroom, id_student) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classRoomAttendanceDetail + '/' + id_classroom + "/" + id_student, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('classroom attendances', responseJson);
                if (responseJson.status === "DETAIL_ATTENDANCE")
                    resolve(responseJson.attendances);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let clientEdit = function(payload) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classroomEditClient, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(payload)
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('EDIT_CLIENT_OK', responseJson);
                if (responseJson.status === "EDIT_CLIENT_OK")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const ClassRoomInsideAPI = {
    detailInside: detailInside,
    detailAttendances: detailAttendances,
    postAdd: postAdd,
    postEdit: postEdit,
    postRemove: postRemove,
    lessonAdd: lessonAdd,
    lessonEdit: lessonEdit,
    lessonRemove: lessonRemove,
    lessonCheckIn: lessonCheckIn,
    clientEdit: clientEdit
};

module.exports = ClassRoomInsideAPI;
