import React, {Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, DropdownItem} from 'reactstrap';
import {ClassRoomInsideAPI, LeadLogAPI} from "api";

class DeleteClassroomLesson extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    showModal() {
        const self = this;
        global.SweetAlert.fire({
            title: "Elimina lezione",
            text: 'Vuoi davvero eliminare la lezione? Perderai le presenze dei corsisti ad essa assegnata.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, elimina',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    ClassRoomInsideAPI.lessonRemove(self.props.id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Si è verificato un errore durante la cancellazione della lezione.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Lezione eliminata!',
                    text: 'Lezione eliminata correttamente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.props.onResolve();
            }
        })
    }


    render() {
        return (
            <React.Fragment>
                <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
                    <i className="ri-delete-bin-5-fill fs-20 text-danger"></i>
                </li>
            </React.Fragment>
        )
    }
}

export default DeleteClassroomLesson;
