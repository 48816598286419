import React, {useEffect, useRef, useState} from 'react';
import {
    Alert,
    Badge, Button,
    Card,
    CardBody,
    Col,
    Container, Input, Nav, NavItem, NavLink,
    Row, Spinner, TabContent, TabPane
} from 'reactstrap';

import {ClassRoomAPI, ClassRoomInsideAPI, LeadAPI, PaymentAPI} from "api";
import {Link, useParams, useHistory} from "react-router-dom";
import {
    DeleteClassroomLesson,
    GestClassroomLesson, GestClassroomMultipleLesson,
    Loading,
    RevertClassroom,
    Utils
} from "custom";

import SimpleBar from "simplebar-react";


const moment = require("moment");

const LessonInside = ({lessons, classroomId, onResolve, classroom}) => {

    const isAdmin = (Utils.getUserData().role === "ADMIN");
    const isTeacher = (Utils.getUserData().role === "TEACHER");
    const isClient = (Utils.getUserData().role === "CLIENT");

    const simpleBarRef = useRef(null);

    const {id} = useParams();

    useEffect(() => {
        if (simpleBarRef.current) {
            const scrollEl = simpleBarRef.current.getScrollElement();
            scrollEl.scrollTop = scrollEl.scrollHeight;
        }
    }, []);

    const printBadgeLesson = (minutesDiff) => {

        if (minutesDiff >= 16)
            return <span className="badge bg-soft-secondary text-secondary align-middle">Programmata</span>

        if (minutesDiff < 16 && minutesDiff > -120)
            return <span className="badge bg-soft-primary text-primary align-middle">In corso</span>

        if (minutesDiff < -120)
            return <span className="badge bg-soft-success text-success align-middle">Completata</span>
    }


    const checkInLesson = (lesson) => {

        if (isAdmin || isTeacher) {
            window.open(lesson.url_lesson, '_blank');
        } else {
            ClassRoomInsideAPI.lessonCheckIn(lesson.id).then((url) => {
                window.open(url, '_blank');
            }).catch((err) => {
                console.error(err)
            })
        }
    }

    const getLessonEnabled = (minutesDiff, l) => {

        if (l.url_lesson !== "" && l.url_lesson !== null) {
            if (minutesDiff < 16 && minutesDiff > -120)
                return true;
        }

        return false;
    }


    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h5 className="card-title">Lezioni</h5>
                    {(isAdmin || isTeacher) ? <div className="mb-2 mt-1">
                        <GestClassroomLesson id_classroom={classroomId} onResolve={onResolve} classroom={classroom}/>
                        &nbsp;
                        <GestClassroomMultipleLesson id_classroom={classroomId} onResolve={onResolve} classroom={classroom} />
                    </div> : null}
                    <SimpleBar ref={simpleBarRef} style={{ height: "500px", overflowX: "hidden" }} className="px-3 mx-n3 mb-2">
                        {(lessons.length > 0) ? <div className="acitivity-timeline py-3">
                            {lessons.map((l, i) => {

                                let minutesDiff = moment(l.date_hour).diff(moment(), "minutes")

                                return <div className="acitivity-item py-3 d-flex" key={i}>
                                    <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                                        <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                                            <i className="mdi mdi-calendar-clock" />
                                        </div>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h6 className="mb-1"><b>{moment(l.date_hour).format("ddd DD MMM YYYY")}</b> {printBadgeLesson(minutesDiff)} {(isAdmin || (isTeacher && minutesDiff > 60)) ? <span><GestClassroomLesson preset={l} id_classroom={classroomId} onResolve={onResolve} classroom={classroom}/>&nbsp;<DeleteClassroomLesson id={l.id} onResolve={onResolve}/></span> : null}</h6>
                                        <p className="mb-1"><b>ore {moment(l.date_hour).format("HH:mm")}</b></p>
                                        {((isAdmin || isTeacher) && !l.url_lesson) ? <span className="badge bg-soft-warning text-warning align-middle"><i className="mdi mdi-link-off" /> Link non inserito</span> : null}
                                        {(getLessonEnabled(minutesDiff, l)) ? <Button size="sm" color="secondary" className="mt-2" onClick={() => checkInLesson(l)}><i className="mdi mdi-video-check"></i> Accedi alla lezione</Button> : null}
                                    </div>
                                </div>
                            })}
                        </div> : <Alert color="dark" >Nessuna lezione trovata</Alert>}
                    </SimpleBar>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default LessonInside;
