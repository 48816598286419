import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, FormText } from 'reactstrap';
import {AuthAPI, ClassRoomTrialAPI} from "api";
import md5 from "md5";
import {BigPlayButton, Player} from "video-react";


const moment = require("moment")

class RedirectTrial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    startTrial() {
        this.setState({loading: true});
        ClassRoomTrialAPI.startTrial(this.props.token.string).then((res) => {
            this.setState({loading: false});
            window.location.replace(res.link);
        }).catch(() => {
            this.setState({loading: false});
            global.SweetAlert.fire({
                title: 'Si è verificato un errore',
                text: 'Purtroppo non è stato possibile accedere alla lezione, riprova più tardi o contatta il tuo consulente',
                icon: 'error',
                allowOutsideClick: false,
                showCancelButton: false,
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                confirmButtonText: 'Riprova',
                buttonsStyling: false
            })
        })
    }


    render() {
        document.title = "Lezione di Prova | CLTV Cloud";

        require("video-react/dist/video-react.css");

        let teacher = this.props.token.trial.teacher;
        let dateTime = this.props.token.trial.dateTime;
        let link = this.props.token.trial.link;

        let enabledClassroom = false;

        if (link && moment(dateTime).diff(moment(), "minutes") <= 10)
            enabledClassroom = true;



        return <React.Fragment>
                    <div className="text-center mt-2">
                        <h4 className="text-primary">Benvenuto nella lezione di prova di Italiani in Germania!</h4>
                        <h5 className="text-primary">La tua avventura inizia qui 🚀</h5>
                    </div>
                    <div className="p-2 mt-4">
                        <p>Siamo entusiasti di darvi il benvenuto nella nostra scuola di lingue, dove vi offriamo l'opportunità di imparare il tedesco in modo divertente e coinvolgente.</p>
                        <p>Sappiamo quanto sia importante per voi acquisire competenze linguistiche per sfruttare al massimo la vostra esperienza in Germania, e siamo qui per aiutarvi in questo viaggio.</p>
                        {(teacher !== null) ? <p>Il Vostro Insegnante per questa lezione di prova sarà <strong>{teacher}</strong></p> : null}
                        {(dateTime !== null) ? <p>La vostra lezione di prova si terrà <strong>{moment(dateTime).format("ddd DD MMM YYYY")}</strong> alle <strong>{moment(dateTime).format("HH:mm")}</strong>.</p> : null}
                    </div>

                    <div className="p-2 mt-4">
                        <h5 className="text-primary">Come partecipare</h5>
                        <p><u>Quando sarà il momento, il pulsante sottostante si abiliterà</u>, permettendovi di accedere alla vostra lezione di prova tramite Zoom. Sarà un'opportunità eccellente per conoscerci, esplorare il nostro metodo di insegnamento e iniziare il vostro percorso nell'apprendimento della lingua tedesca.</p>
                        <p>Non vediamo l'ora di aiutarvi a raggiungere i vostri obiettivi linguistici e di condividere con voi la bellezza della lingua tedesca. Grazie per aver scelto la nostra accademia come vostro partner nell'apprendimento delle lingue. Ci vediamo nella lezione di prova!</p>
                    </div>

                    <div style={{width: "100%"}}>
                        <Player
                            autoPlay={true}
                            src={"https://demo.cltv.online/video-trial.mp4"}
                            preload='auto'
                            playsInline={true}
                        >
                            <BigPlayButton position="center" hide={false} />
                        </Player>
                    </div>

                    <div>
                        <div className="p-2 mt-4">
                            <Button disabled={(!enabledClassroom)} color="success" className="btn btn-success w-100" onClick={() => this.startTrial()}>Accedi alla Lezione di Prova {(this.state.loading ? <Spinner size='sm' type='grow' /> : null)}</Button>
                        </div>
                        {(!enabledClassroom) ? <p className="text-danger"><small>La lezione di Prova non è ancora disponibile!</small></p> : null}
                        {(!enabledClassroom) ? <p className="text-danger"><small>Torna in questa pagina <strong>{moment(dateTime).format("ddd DD MMM YYYY")}</strong> alle ore <strong>{moment(dateTime).format("HH:mm")}</strong> per accedere alla lezione</small></p> : null}
                    </div>

        </React.Fragment>
    }
}

export default RedirectTrial;
