import _Loading from './Loading';
import _Utils from './Utils';
import _CustomModal from './CustomModal';
import _GeoForm from './GeoForm';

import _GestAdmin from "./Components/AdminAction/GestAdmin";
import _DeleteAdmin from "./Components/AdminAction/DeleteAdmin";

import _GestClassroomTrial from "./Components/ClassroomTrialAction/GestClassroomTrial";
import _GestLinkClassroomTrial from "./Components/ClassroomTrialAction/GestLinkClassroomTrial";
import _DeleteClassroomTrial from "./Components/ClassroomTrialAction/DeleteClassroomTrial";

import _GestModule from "./Components/ModuleAction/GestModule";
import _ReassignModule from "./Components/ModuleAction/ReassignModule";
import _DeleteModule from "./Components/ModuleAction/DeleteModule";

import _ListCourse from "./Components/CourseAction/ListCourse";
import _ListAvailableCourse from "./Components/CourseAction/ListAvailableCourse";
import _DeleteCourse from "./Components/CourseAction/DeleteCourse";

import _ListSell from "./Components/SellAction/ListSell";
import _SellSetting from "./Components/SellAction/SellSetting";
import _GestSell from "./Components/SellAction/GestSell";
import _DeleteSell from "./Components/SellAction/DeleteSell";

import _ListPayment from "./Components/PaymentAction/ListPayment";
import _PaymentSetting from "./Components/PaymentAction/PaymentSetting";

import _ListReminder from "./Components/ReminderAction/ListReminder";

import _CreateTeacher from "./Components/TeacherAction/AddTeacher";
import _DeleteTeacher from "./Components/TeacherAction/DeleteTeacher";
import _EditTeacher from "./Components/TeacherAction/EditTeacher";

import _EditLeadLog from "./Components/LeadLogAction/EditLeadLog";
import _DeleteLeadLog from "./Components/LeadLogAction/DeleteLeadLog";

import _EditStudent from "./Components/ClassroomInsideAction/EditStudent";
import _EditPost from "./Components/ClassroomInsideAction/EditPost";
import _DeletePost from "./Components/ClassroomInsideAction/DeletePost";

import _GestClassroomLesson from "./Components/ClassroomInsideAction/GestClassroomLesson";
import _GestClassroomMultipleLesson from "./Components/ClassroomInsideAction/GestClassroomMultipleLesson";
import _DeleteClassroomLesson from "./Components/ClassroomInsideAction/DeleteClassroomLesson";

import _CreateConsulent from "./Components/ConsulentAction/Create";

import _EditLead from "./Components/LeadAction/EditLead";
import _DeleteLead from "./Components/LeadAction/DeleteLead";

import _ListConsulentClassroom from "./Components/ClassRoomAction/ListConsulentClassroom";
import _DeleteClassroom from "./Components/ClassRoomAction/DeleteClassroom";
import _RevertClassroom from "./Components/ClassRoomAction/RevertClassroom";
import _ClassroomSetting from "./Components/ClassRoomAction/ClassroomSetting";

import _ContactReporter from "./Components/ReporterAction/ContactReporter";
import _EditReporter from "./Components/ReporterAction/EditReporter";
import _DeleteReporter from "./Components/ReporterAction/DeleteReporter";

import _TransferNetworker from "./Components/NetworkerAction/Transfer"

import _Params from "./Params";


export const Loading = _Loading;
export const CustomModal = _CustomModal;
export const Utils = _Utils;

export const Params = _Params;
export const GeoForm = _GeoForm;

export const GestAdmin = _GestAdmin;
export const DeleteAdmin = _DeleteAdmin;


export const CreateTeacher = _CreateTeacher;
export const DeleteTeacher = _DeleteTeacher;
export const EditTeacher = _EditTeacher;

export const EditLeadLog = _EditLeadLog;
export const DeleteLeadLog = _DeleteLeadLog;

export const EditPost = _EditPost;

export const EditStudent = _EditStudent;
export const DeletePost = _DeletePost;

export const GestClassroomLesson = _GestClassroomLesson;
export const GestClassroomMultipleLesson = _GestClassroomMultipleLesson;
export const DeleteClassroomLesson = _DeleteClassroomLesson;



export const ListCourse = _ListCourse;
export const ListAvailableCourse = _ListAvailableCourse;
export const DeleteCourse = _DeleteCourse;


export const ListSell = _ListSell;
export const SellSetting = _SellSetting;
export const GestSell = _GestSell;
export const DeleteSell = _DeleteSell;

export const ListPayment = _ListPayment;
export const PaymentSetting = _PaymentSetting;

export const ListReminder = _ListReminder;

export const CreateConsulent = _CreateConsulent;

export const EditLead = _EditLead;
export const DeleteLead = _DeleteLead;

export const GestClassroomTrial = _GestClassroomTrial;
export const GestLinkClassroomTrial = _GestLinkClassroomTrial;
export const DeleteClassroomTrial = _DeleteClassroomTrial;

export const GestModule = _GestModule;
export const ReassignModule = _ReassignModule;
export const DeleteModule = _DeleteModule;

export const ListConsulentClassroom = _ListConsulentClassroom;
export const DeleteClassroom = _DeleteClassroom;
export const RevertClassroom = _RevertClassroom;
export const ClassroomSetting = _ClassroomSetting;


export const ContactReporter = _ContactReporter;
export const EditReporter = _EditReporter;
export const DeleteReporter = _DeleteReporter;
export const TransferNetworker = _TransferNetworker;
