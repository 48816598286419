import React, {Component} from 'react'
import {Grid, _} from 'gridjs-react';

import {ClassroomSetting, DeleteClassroom, Loading, PaymentSetting, Utils} from "custom";
import {
    Alert,
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle,
    Form,
    Input, Label,
    Row,
    Spinner,
    UncontrolledDropdown
} from 'reactstrap';
import {Link, withRouter} from 'react-router-dom';
import {ClassRoomAPI} from 'api';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import loading from "custom/Loading";

const moment = require("moment")

class ClassRoomTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classrooms: [],
            loading: false,
            error: false,
            errors: null,
        };
    }

    componentDidMount() {
        this.getClassRoomList()
    }

    getClassRoomList = (isCompleted) => {
        ClassRoomAPI.listClient(isCompleted).then((classrooms) => {
            this.setState({classrooms: classrooms.filter((s) => s.status === "ACTIVE").concat(classrooms.filter((s) => s.status === "WAITING")).concat(classrooms.filter((s) => s.status === "COMPLETED")), loading: false})
        }).catch((err) => {
            this.setState({loading: false, error: true});
        })
    }

    render() {

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger">Si è verificato un errore durante il recupero della lista delle classi.</Alert>
            </React.Fragment>
        }

        const self = this;
        let isTeacher = Utils.getUserData().role === "TEACHER";
        let isClient = Utils.getUserData().role === "CLIENT";

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Elenco Classi" pageTitle="Classi"/>

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <div className="d-flex align-items-center m-2">
                                        <div className="flex-grow-1">
                                            <div className="form-check form-switch form-switch-custom form-switch-primary mb-1">
                                                <Input className="form-check-input" type="checkbox" role="switch" checked={this.state.showCompleted}
                                                       onChange={(e) => this.getClassRoomList(e.target.checked)} />
                                                <Label className="form-check-label">Mostra classi completate</Label>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>

                        <Row id="job-list">
                            {/*{(isClient) ? <Col lg={3} md={6} id="job-widget">*/}
                            {/*    <Card className="card-height-100 bg-info bg-job">*/}
                            {/*        <CardBody className="p-5">*/}
                            {/*            <h2 className="lh-base text-white">*/}
                            {/*                Vuoi accedere*/}
                            {/*            </h2>*/}
                            {/*            <p className="text-white text-opacity-75 mb-0 fs-14">*/}
                            {/*                Don't miss your opportunity to improve your skills!*/}
                            {/*            </p>*/}
                            {/*            <div className="mt-5 pt-2">*/}
                            {/*                <Button type="button" className="btn btn-light w-100">*/}
                            {/*                    View More{" "}*/}
                            {/*                    <i className="ri-arrow-right-line align-bottom"></i>*/}
                            {/*                </Button>*/}
                            {/*            </div>*/}
                            {/*        </CardBody>*/}
                            {/*    </Card>*/}
                            {/*</Col> : null}*/}
                            {(this.state.classrooms || []).map((item, key) => (
                                <Col lg={3} md={6} sm={12} key={key}>
                                    <Card>
                                        <CardBody>
                                            <div className="avatar-sm mb-4">
                                                <div className="avatar-title bg-light rounded">
                                                    <img src={require("../../assets/images/avatar-cltv.png")} alt="" className="avatar-xs" />
                                                </div>
                                            </div>
                                            <Link to={"/classroom/inside/" + item.id}>
                                                <h5>{item.level.name}</h5>
                                            </Link>
                                            <p className="text-muted">{item.name.split(" - ")[2]} </p>
                                            <div className="d-flex gap-4 mb-3">
                                                <div>
                                                    <i className="mdi mdi-school text-primary me-1 align-bottom"></i>{" "}
                                                    {item.teacher?.firstname} {item.teacher?.lastname}
                                                </div>
                                                {(item.status !== "ACTIVE" && isClient) ? null : <div>
                                                    <i className="ri-calendar-2-fill text-primary me-1 align-bottom"></i>{" "}
                                                    {moment(item.date_start, "YYYY-MM-DD").format("DD MMM YYYY")}
                                                </div>}
                                            </div>
                                            <div className="hstack gap-2">
                                                {(item.status === "WAITING") ? <span className="badge bg-warning-subtle bg-soft-warning text-warning">
                                                  IN PARTENZA
                                                </span> : null}
                                                {(item.status === "ACTIVE") ? <span className="badge bg-primary-subtle bg-soft-primary text-primary">
                                                  IN CORSO
                                                </span> : null}
                                                {(item.status === "COMPLETED") ? <span className="badge bg-success-subtle bg-soft-success text-success">
                                                  COMPLETATO
                                                </span> : null}
                                            </div>
                                            <div className="mt-4 hstack gap-2">
                                                {(item.status !== "ACTIVE" && isClient) ? null : <Button href={"/classroom/inside/" + item.id} className="btn btn-soft-primary w-100">
                                                    <i className="mdi mdi-school text-primary me-1"></i> Entra in Classe
                                                </Button>}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>


                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(ClassRoomTable)
