import React, {useEffect, useState} from 'react';
import {
    Alert,
    Badge,
    Card,
    CardBody,
    Col,
    Container, Input, Nav, NavItem, NavLink,
    Row, Spinner, TabContent, TabPane
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';

import {ClassRoomAPI, ClassRoomInsideAPI, LeadAPI, PaymentAPI} from "api";
import {Link, useParams, useHistory} from "react-router-dom";
import {
    Loading,
    RevertClassroom,
    Utils
} from "custom";

import {_, Grid} from "gridjs-react";
import BoardInside from "./ClassroomInside/BoardInside";
import LessonInside from "./ClassroomInside/LessonInside";
import StudentInside from "./ClassroomInside/StudentInside";

const moment = require("moment");

const ClassroomMainInside = () => {
    document.title = "Dettaglio Classe | CLTV Cloud";

    const history = useHistory()

    const [loading, setLoading] = useState(true);
    const [classroom, setClassroom] = useState(null);
    const [lessons, setLessons] = useState([]);
    const [posts, setPosts] = useState([]);

    const [activeTab, setActiveTab] = useState("1");

    const [error, setError] = useState(false);

    const [editClassEnd, setEditClassEnd] = useState(false);

    const isAdmin = (Utils.getUserData().role === "ADMIN");
    const isTeacher = (Utils.getUserData().role === "TEACHER");

    const {id} = useParams();

    useEffect(() => {
        getDetailClassroom();
    }, []);

    const changeTab = (tab) => {
        setActiveTab(tab)
    }



    const getDetailClassroom = () => {
        ClassRoomInsideAPI.detailInside(id).then(async (res) => {
            setClassroom(() => res)
            setLoading(false)
            setError(false);

        }).catch((err) => {
            setError(true);
            console.error(err);
        })
    }

    const printStatus = () => {
        switch(classroom.status) {
            case "WAITING":
                return <div className="badge rounded-pill bg-warning fs-12">In Programmazione</div>;
            case "ACTIVE":
                return <div className="badge rounded-pill bg-primary fs-12">In Corso</div>;
            case "COMPLETED":
                return <div className="badge rounded-pill bg-success fs-12">Completata</div>;
            default:
                return null;
        }
    }

    const setEndClassroom = (dateEnd) => {
        setLoading(() => true)
        ClassRoomAPI.editEnd(id, dateEnd).then(() => {
            getDetailClassroom();
            setEditClassEnd(false)
            global.SweetAlert.fire({
                icon: 'success',
                title: 'Data fine classe modificata!',
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
        }).catch((e) => {
            setLoading(() => false)

            let message = 'Non è stato possibile modificare la data di fine classe. Riprova più tardi.';

            if (e.status === "CLASSROOM_CONFLICT")
                message = "La data di fine classi va in conflitto con altre classi già esistenti.";

            global.SweetAlert.fire({
                icon: 'error',
                title: 'Si è verificato un errore :(',
                text: message,
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
        })
    }


    if (error) {
        return <React.Fragment>
            <div className="page-content">
                <Alert color="danger" >Si è verificato un errore durante il recupero del dettaglio classe.</Alert>
            </div>
        </React.Fragment>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {(loading) ? <Loading/> : <React.Fragment>
                        <Row>
                            <Col lg={12}>
                                <Card className="mt-n4 mx-n4">
                                    <div className="bg-soft-warning">
                                        <CardBody className="pb-0 px-4">
                                            <Row className="mb-3">
                                                <div className="col-md">
                                                    <Row className="align-items-center g-3">
                                                        <div className="col-md-auto">
                                                            <div className="avatar-md">
                                                                <div className="avatar-title bg-white rounded-circle">
                                                                    <img src={require("../../assets/images/avatar-cltv.png")} alt="" className="avatar-md" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div>
                                                                <h4 className="fw-bold">{classroom.name}</h4>
                                                                <div className="hstack gap-3 flex-wrap">
                                                                    <div><i className="ri-user-2-fill align-bottom me-1"></i> Docente: <b>{classroom.teacher.firstname} {classroom.teacher.lastname}</b></div>
                                                                    {(classroom.date_start) ? <React.Fragment>
                                                                        <div className="vr"></div>
                                                                        <div>Data Inizio: <span className="fw-medium">{moment(classroom.date_start, "YYYY-MM-DD").format("DD MMM YYYY")}</span></div>
                                                                    </React.Fragment> : null}
                                                                    {(classroom.date_end) ? <React.Fragment>
                                                                        <div className="vr"></div>
                                                                        <div>Data Fine: {(!editClassEnd) ? <span className="fw-medium">{moment(classroom.date_end, "YYYY-MM-DD").format("DD MMM YYYY")} {(isTeacher) ? <i className="ri-pencil-fill text-info cursor-pointer" onClick={() => setEditClassEnd(true)}></i> : null}</span> : <Input type="date" defaultValue={classroom.date_end} min={moment(classroom.date_start).add(2, "days").toISOString().split("T")[0]} onChange={(e) => setEndClassroom(e.target.value)}/>}</div>
                                                                    </React.Fragment> : null}
                                                                    <div className="vr"></div>
                                                                    {printStatus()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>
                                            </Row>

                                            <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                                <NavItem>
                                                    <NavLink
                                                        className={(activeTab === "1") ? "active fw-semibold" : ""}
                                                        onClick={() => { changeTab('1'); }}
                                                        href="javascript:void(0)">
                                                        Bacheca
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={(activeTab === "2") ? "active fw-semibold" : ""}
                                                        onClick={() => { changeTab('2'); }}
                                                        href="javascript:void(0)">
                                                        Lezioni
                                                    </NavLink>
                                                </NavItem>
                                                {(Utils.getUserData().role !== "CLIENT") ? <NavItem>
                                                    <NavLink
                                                        className={(activeTab === "3") ? "active fw-semibold" : ""}
                                                        onClick={() => { changeTab('3'); }}
                                                        href="javascript:void(0)">
                                                        Corsisti
                                                    </NavLink>
                                                </NavItem> : null}
                                            </Nav>
                                        </CardBody>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <TabContent activeTab={activeTab} className="text-muted">
                                    <TabPane tabId="1">
                                        <BoardInside posts={classroom.posts} classroomId={classroom.id} onResolve={getDetailClassroom}/>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <LessonInside lessons={classroom.lessons || []} classroomId={classroom.id} classroom={classroom} onResolve={getDetailClassroom}/>
                                    </TabPane>
                                    {(Utils.getUserData().role !== "CLIENT") ? <TabPane tabId="3">
                                        <StudentInside groups={classroom.groups || []} classroomId={classroom.id} onResolve={getDetailClassroom} />
                                    </TabPane> : null}
                                </TabContent>
                            </Col>
                        </Row>
                    </React.Fragment>}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ClassroomMainInside;
